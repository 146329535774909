import React from 'react';
import { NavLink, Link } from 'react-router-dom';

function Header() {
  return (
    <header>
      <Link to="/" className="logo">
        Shajar
      </Link>

      <nav>
        {/* <NavLink
          to="/aboutus"
          className={({ isActive }) => (isActive ? 'active' : '')}
        >
          About us
        </NavLink> */}
        <NavLink
          to="/services"
          className={({ isActive }) => (isActive ? 'active' : '')}
        >
          Services
        </NavLink>
        <NavLink
          to="/contact"
          className={({ isActive }) => (isActive ? 'active' : '')}
        >
          Contact
        </NavLink>
      </nav>
    </header>
  );
}

export default Header;
